<template>
    <v-container id="title-bar">
        <!-- logo image for screens larger than xs -->
        <v-img
          :src="require('../assets/HeadlineHazeLogo.png')"
          class="mb-4 d-none d-sm-flex"
          contain
          height="200"
        />
        <!-- logo image for screens xs -->
        <v-img
          :src="require('../assets/HeadlineHazeLogo.png')"
          class="mt-n1 mb-0 d-flex d-sm-none"
          contain
          height="125"
        />
        
        <!-- <h4 class="text-center font-italic my-1">High Noon: /ˈhɑɪ ˈnun/ the time of a decisive confrontation or contest</h4>  Pitting headlines head to head to help you sift through bias and expose narratives, letting you make a decision for yourself...-->
        <div class="text-center mt-6 ">Opposing viewpoints, head to head.</div>
        <div class="text-center description mb-n1">Sift through media bias. Expose narratives. Make a decision for yourself.</div>
    </v-container>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'TitleBar',

  })
</script>

<style scoped>
.description {
  font-style: italic;
}
</style>